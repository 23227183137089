<template>
  <v-app>
    <router-view />
  </v-app>
</template>
<style>
.bg_color {
  background: linear-gradient(to bottom, #374fec 50%, #c6d3fc 50%) !important;
}
.borderRadiusVariant0 {
  border-radius: 0px !important;
}
.borderVariant0 {
  border-top: 1px solid black !important;
  border-left: 1px solid black !important;
  border-right: 1px solid black !important;
}
.step_padding {
  padding-top: 5.7px !important;
  padding-bottom: 5.7px !important;
}
.underlined_Text {
  text-decoration: underline !important;
  text-underline-offset: 7px !important;
}

.cursorPointer {
  cursor: pointer !important;
}

.hover_SelectedItem:hover {
  background-color: #96bfe9;
}

.fontVariant5 {
  font-size: 14px !important;
}


.fontVariant2 {
  font-size: 16px !important;
}

.fontVariant3 {
  font-size: 12px !important;
}

.fontVariant4 {
  font-size: 8px !important;
}

.oswald-defult_font {
  font-optical-sizing: auto !important;
  font-weight: 700 !important;
  font-style: normal !important;
}

.heading1 {
  font-weight: bolder !important;
  font-size: 32px !important;
  font-optical-sizing: auto !important;
}

.heading2 {
  font-weight: bolder !important;
  font-size: 24px !important;
}

.heading3 {
  font-weight: normal !important;
  font-size: 20px !important;
}

.heading4 {
  font-weight: 500 !important;
  font-size: 18px !important;
}
.heading5 {
  font-size: 16px !important;
}
.tableClassVariant1 table {
  border-collapse: separate;
  border-spacing: 0 0px !important;
}

.tableClassVariant1 th {
  height: 24px !important;
  text-align: center;
  border: 1px solid #d5d7dd;
  padding: 5px;
  font-size: 12px !important;
}

.tableClassVariant1.v-table td {
  height: 24px !important;
  font-weight: normal !important;
  font-size: 12px !important;
}

.tableClassVariant1 th {
  background-image: linear-gradient(#f6faff, #d5d7dd);
  color: #8e8484 !important;
  border-bottom: 1px solid #d5d7dd !important;
}

.tableClassVariant1 table th:first-child {
  border-radius: 4px 0 0 4px;
}

.tableClassVariant1 table th:last-child {
  border-radius: 0px 0px 0px 0px;
}

.tableClassVariant1 td:first-child {
  border-radius: 0px 0px 0px 0px;
}

.tableClassVariant1 td:last-child {
  border-radius: 0px 6px 6px 0px;
}

.tableClassVariant1 tr:nth-child(even) {
  background-color: #f6faff !important;
}

.field_height .v-field {
  min-height: 12px !important;
  max-height: 30px !important;
  display: flex !important;
  align-items: center !important;
}

.field_label_size.v-input .v-label {
  font-size: 10px !important;
  opacity: 1 !important;
}

.field_font .v-field__input {
  font-size: 12px !important;
}

.priceListfield_font .v-field__input {
  font-size: 12px !important;
}

html {
  overflow: hidden !important;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

html::-webkit-scrollbar {
  width: 0;
  height: 0;
}
/* 
.scrollbar_custom {
  scrollbar-color: #bdd2fa transparent !important;
} */

.press-start-2p-regular {
  font-family: "Press Start 2P", system-ui !important;
  font-weight: 800 !important;
  font-style: normal !important;
  font-size: 32px !important;
}

.gamingFont1 {
  font-family: "Press Start 2P", sans-serif !important;
  font-weight: normal !important;
  font-style: normal !important;
}

.gamingFont2 {
  font-family: "Press Start 2P", sans-serif !important;
  font-size: 12px !important;
  font-optical-sizing: auto !important;
  font-weight: normal !important;
}
</style>
