<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent"></Snackbar>
    <Overlay :overlay="overlay" />
    <v-app>
      <v-app-bar app density="compact" clipped-left color="white" elevation="1">
        <div class="ml-4">
          <v-img
            :width="55"
            aspect-ratio="1/1"
            cover
            max-height="45"
            src="@/assets/new_gamexentral_logo.jpg"
          ></v-img>
        </div>
      </v-app-bar>
      <v-main>
        <v-card
          variant="flat"
          class="overflow-auto whole-background"
          elevation="0"
          :height="windowHeight"
        >
          <v-card-text
            class="d-flex align-center justify-center"
            style="height: 100%"
          >
            <v-row no-gutters class="d-flex align-center justify-center">
              <v-col cols="12" xs="8" sm="8" md="8">
                <v-card elevation="0" class="">
                  <v-row no-gutters>
                    <v-col cols="12" xs="12" sm="12" md="6">
                      <v-card class="card-background" elevation="0"> </v-card>
                    </v-col>
                    <v-divider vertical color="black"></v-divider>
                    <v-col cols="12" xs="12" sm="12" md="6">
                      <v-card
                        height="500"
                        elevation="0"
                        style="background: #eaf0f6"
                        density="compact"
                      >
                        <div
                          align="center"
                          :class="[
                            'mb-2',
                            { 'mt-12': step === 1 || step === 2 },
                          ]"
                        >
                          <div
                            align="end"
                            class="mr-6 my-2"
                            v-if="step === 2 || step === 3"
                          >
                            <v-icon
                              size="x-large"
                              color="primary"
                              @click="goBack"
                              :disabled="otpLoading"
                              >mdi-arrow-left-bold</v-icon
                            >
                          </div>
                          <v-img
                            src="@/assets/new_gamexentral_logo.jpg "
                            contain
                            height="80px"
                            width="200px"
                          ></v-img>
                        </div>

                        <h2 class="text-center ma-1 heading2">
                          {{ step === 3 ? "Create Account" : "Login" }}
                        </h2>

                        <h1 class="text-center ma-1 heading3">
                          Welcome to GameXentral-Bms
                        </h1>

                        <v-window v-model="step" class="px-1">
                          <v-window-item :value="1">
                            <v-card-text>
                              <v-form ref="form" lazy-validation class="mt-2">
                                <div class="ml-4 my-2 fontVariant3">
                                  Please enter your EmailID*
                                </div>
                                <v-text-field
                                  elevation="0"
                                  label="EmailID*"
                                  variant="solo"
                                  class="field_font field_label_size"
                                  :rules="[(v) => !!v || 'Required']"
                                  v-model="EmailID"
                                  @keyup.enter="ValidateSignUpForm"
                                >
                                  <template v-slot:prepend-inner>
                                    <v-icon color="primary">mdi-email</v-icon>
                                  </template></v-text-field
                                >
                              </v-form>

                              <div class="d-flex align-center mx-1 px-1 mb-2">
                                <v-btn
                                  class="fontVariant3 rounded-lg"
                                  block
                                  size="large"
                                  variant="flat"
                                  :loading="loading"
                                  color="primary"
                                  @click="ValidateSignUpForm()"
                                  >Send otp</v-btn
                                >
                              </div>
                              <!-- <div align="center" class="fontVariant3">
                                Don't have an account
                                <u
                                  class="fontVariant3 text-primary cursorPointer"
                                  @click="step = 3"
                                  >Create account</u
                                >
                              </div> -->
                            </v-card-text>
                          </v-window-item>
                          <v-window-item :value="2">
                            <v-card-text>
                              <div class="fontVariant3 text-center">
                                Please enter the OTP that was sent to your
                                registered EmailID:
                              </div>

                              <v-otp-input
                                variant="solo"
                                elevation="0"
                                :length="4"
                                v-model="otp"
                                :loading="otpLoading"
                              ></v-otp-input>
                              <div class="d-flex align-center mx-3 mb-2">
                                <v-btn
                                  :disabled="otp.length < 4 || otpLoading"
                                  class="fontVariant3"
                                  block
                                  variant="flat"
                                  :loading="loading"
                                  color="primary"
                                  @click="validateVerifyOtpMethod()"
                                  >Login</v-btn
                                >
                              </div>

                              <div class="fontVariant3 text-center">
                                Didn't receive the OTP?
                                <span
                                  class="fontVariant3 text-primary cursorPointer"
                                  @click="ValidateSignUpForm"
                                  >Resend</span
                                >
                                code.
                              </div>
                            </v-card-text>
                          </v-window-item>
                          <v-window-item :value="3">
                            <v-card-text>
                              <v-form
                                ref="formCreateAccount"
                                lazy-validation
                                class="mt-2"
                              >
                                <v-text-field
                                  elevation="0"
                                  label="Username*"
                                  variant="solo"
                                  v-model="createUserAccount.user_name"
                                  class="field_font field_label_size"
                                  :rules="[(v) => !!v || 'Required']"
                                >
                                  <template v-slot:prepend-inner>
                                    <v-icon color="primary"
                                      >mdi-account-box</v-icon
                                    >
                                  </template></v-text-field
                                >
                                <v-text-field
                                  v-model="createUserAccount.contact_number"
                                  elevation="0"
                                  label="EmailID*"
                                  variant="solo"
                                  class="field_font field_label_size"
                                  :rules="[(v) => !!v || 'Required']"
                                >
                                  <template v-slot:prepend-inner>
                                    <v-icon color="primary">mdi-email</v-icon>
                                  </template></v-text-field
                                >
                              </v-form>
                              <div class="d-flex align-center mx-3 mb-2">
                                <v-btn
                                  class="fontVariant3"
                                  block
                                  variant="flat"
                                  :loading="loadingUser"
                                  color="primary"
                                  @click="validateCreateAccount()"
                                  >Create account</v-btn
                                >
                              </div>
                              <div align="center" class="fontVariant3">
                                Already a user
                                <u
                                  class="fontVariant3 text-primary cursorPointer"
                                  @click="step = 1"
                                  >Sign in</u
                                >
                              </div>
                            </v-card-text>
                          </v-window-item>
                        </v-window>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import Snackbar from "@/components/Extras/Snackbar.vue";
import Overlay from "@/components/Extras/Overlay.vue";
import { signIn, signOut, confirmSignIn } from "aws-amplify/auth";

export default {
  data() {
    return {
      step: 1,
      height: 0,
      windowHeight: 0,
      SnackBarComponent: {},
      overlay: false,
      EmailID: "",
      otp: "",
      loading: false,
      user: "",
      otpLoading: false,
      createUserAccount: {
        user_name: "",
        contact_number: "",
        country_code: "+91",
      },
      loadingUser: false,
    };
  },
  components: {
    Snackbar,
    Overlay,
  },

  watch: {},
  mounted() {
    this.windowHeight = window.innerHeight - 50;

    this.overlay = true;
    setTimeout(() => {
      this.overlay = false;
    }, 400);
  },

  methods: {

    async validateCreateAccount() {
      let isValid = await this.$refs.formCreateAccount.validate();
      if (isValid.valid) {
        let returedResult = await this.createUserAccountMethod();
        // console.log("returedResult", returedResult);
        if (returedResult === "SUCCESS") {
          this.goBack();
        } else {
          // console.log("error returned result", returedResult);
        }
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "error",
          Top: true,
          SnackbarText: "Fields marked with asterisks (*) are mandatory",
        };
      }
    },

    goBack() {
      this.$refs.form.reset();
      this.$refs.formCreateAccount.reset();
      this.otp = "";
      this.step = 1;
    },
    async ValidateSignUpForm() {
      this.loading = true;
      let isValid = await this.$refs.form.validate();
      if (isValid.valid) {
        await this.SignWithOtpMethod();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "error",
          Top: true,
          SnackbarText: "Fields marked with asterisks (*) are mandatory",
        };
        this.loading = false;
      }
    },

    async SignWithOtpMethod() {
      try {
        console.log("this.EmailID.trim()", this.EmailID.trim());
        this.user = await signIn({
          username: this.EmailID.trim(),
          options: {
            authFlowType: "CUSTOM_WITHOUT_SRP",
          },
        });
        console.log("result of SignWithOtpMethod step-1", this.user);
        if (
          this.user.nextStep.signInStep ==
          "CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE"
        ) {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: `Verification OTP sent to ${this.EmailID}`,
          };
          this.step = 2;
        }
      } catch (error) {
        console.log("error in SignWithOtpMethod step-1", error);
        if (error.message == "There is already a signed in user.") {
          await this.logoutMethod(this.EmailID);
        }
        if (
          error.message ===
          "DefineAuthChallenge failed with error Error: NOT_AUTHORIZED : Kindly Sigup."
        ) {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "User Not Found",
          };
        }

        let errorMessage =
          error.message ===
            "DefineAuthChallenge failed with error Error: NOT_AUTHORIZED : Kindly Sigup." ||
          error.message == "There is already a signed in user.";
        if (!errorMessage) {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: error.message,
          };
        }
      } finally {
        this.loading = false;
      }
    },

    async logoutMethod(EmailID) {
      this.loading = true;
      try {
        const user = await signOut({
          username: EmailID,
        });

        await this.ValidateSignUpForm();
      } catch (error) {
        console.error("Error in logoutMethod", error);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "error",
          Top: true,
          SnackbarText: error.message,
        };
        throw error;
      } finally {
        this.loading = false;
      }
    },
    async validateVerifyOtpMethod() {
      this.otpLoading = true;
      try {
        let result = await confirmSignIn({ challengeResponse: this.otp });
        console.log("result", result);
        if (result.nextStep.signInStep === "DONE") {
          this.$store.commit("SET_USEREMAIL", this.EmailID);
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: "Login Success",
          };
          this.ActivateMethod();
        }
      } catch (error) {
        console.log("error in validateVerifyOtpMethod ", error);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.message,
        };
      } finally {
        this.otpLoading = false;
      }
    },
    ActivateMethod() {
      this.otpLoading = true;
      setTimeout(() => {
        this.otpLoading = false;
        this.$router.push("/Organisations");
      }, 500);
    },
  },
};
</script>
<style scoped>
.card-background {
  background-image: url("@/assets/loginPageCard.avif");
  background-size: cover;
  background-position: center;
  height: 500px;

  /* Make sure to set the height */
}

.whole-background {
  background-image: url("@/assets/loginPageBackground.avif");
  background-size: cover;
  background-position: center;
}
</style>
