import { createRouter, createWebHistory } from "vue-router";

import LoginPage from "../views/MainHomePages/LoginPage.vue";
const routes = [
  {
    path: "/",
    name: "LoginPage",
    component: LoginPage,
  },
  {
    path: "/LandingPage",
    name: "LandingPage",

    component: () =>
      import(
        /* webpackChunkName: "LandingPage" */ "../views/MainHomePages/LandingPage.vue"
      ),

    children: [
      {
        path: "/UserManagement",
        name: "UserManagement",
        component: () =>
          import(
            /* webpackChunkName: "UserManagement" */ "../views/ChildHomePages/UserManagement.vue"
          ),
      },
      {
        path: "/DeviceTypes",
        name: "DeviceTypes",
        component: () =>
          import(
            /* webpackChunkName: "DeviceTypes" */ "../views/ChildHomePages/DeviceTypes.vue"
          ),
      },
      {
        path: "/Organisations",
        name: "Organisations",
        component: () =>
          import(
            /* webpackChunkName: "Organisations" */ "../views/ChildHomePages/Organisations.vue"
          ),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
